import { Ability } from "@casl/ability";
import {
  TimeBlockingEvent,
  GoogleCalendarEvent,
  User,
  Organization,
  Role,
  ProjectCategory,
  ProjectReport,
  ProjectNote,
  TimesheetRowByDay,
  UserRole,
  RecurrenceRule,
  Customer,
  UserInsights,
  Task,
  TeamReportRow,
  TaskCategory,
  Plan,
  Subscription,
  ProjectStatus,
  Webhook,
  UserContract,
  TimesheetRowByProject,
  TimesheetRowByClient,
  ProjectStatstics,
  ProjectLinks,
  ProjectUpdate,
  Overhead,
  TeamMember,
  ProjectArea,
} from "../types";
import GoogleCalendar from "../models/GoogleCalendar";
import preferences from "../config/preferences";
import Project from "../models/Project";
import Expense from "../models/Expense";
import Timer from "../models/Timer";

export const toUser = (data: any): User => {
  return {
    uid: data.uid,
    email: data.email,
    nominative: data.nominative,
    imageUrl: data.image_url ?? undefined,
    preferences: { ...preferences, ...data.preferences },
    status: data.status,
    integrations: data.integrations,
    organization: data.organization ? toOrganization(data.organization) : null,
    role: data.role ? toUserRole(data.role) : null,
    hourlyCost: data.hourly_cost || 0,
    contractualHours: data.contractual_hours || 0,
    productivityTarget: data.productivity_target || 0,
    termsOfUse: !!data.terms_of_use,
    privacyPolicy: !!data.privacy_policy,
    cookieConsent: !!data.cookie_consent,
    externalId: data.external_uid || null,
    language: data.language,
  };
};

export const toUserInsights = (data: any): UserInsights => {
  return {
    targetHours: parseInt(data.target_hours) || null,
    workLogged: data.work_logged || 0,
  };
};

export const toRecurreceRule = (data: any): RecurrenceRule => {
  return {
    every: data.every,
    frequency: data.frequency,
    until: data.until ? new Date(data.until) : null,
    repeat: data.repeat,
    dayOfWeeks: data.day_of_weeks,
  };
};

export const toEvent = (data: any): TimeBlockingEvent => {
  return {
    uid: data.uid,
    title: data.title,
    startTime: parseInt(data.start_time),
    endTime: parseInt(data.end_time),
    userId: data.user_id,
    color: data.color,
    elapsedTime: data.elapsed_time === null ? 0 : parseInt(data.elapsed_time),
    sourceId: data.source_id || null,
    source: data.source || null,
    completed: data.completed,
    eventType: data.event_type,
    projectId: data.project_id || null,
    recurrenceId: data.recurrence_id || null,
    recurrence: data.recurrence ? toRecurreceRule(data.recurrence) : null,
    category: data.category ? toTaskCategory(data.category) : null,
    categoryId: data.category ? data.category.uid : null,
    project: data.project
      ? { uid: data.project.uid, title: data.project.title }
      : null,
    taskLinkId: data.task_linked_id || null,
    calanderId: data.calanderId || null,
  };
};

export const toGoogleCalendar = (data: any): GoogleCalendar => {
  return new GoogleCalendar(data.id, data.summary, data.backgroundColor);
};

export const toGoogleCalendarEvent = (data: any): GoogleCalendarEvent => {
  return {
    id: data.id,
    title: data.summary,
    htmlLink: data.htmlLink,
    startTime: new Date(data.start.dateTime).getTime(),
    endTime: new Date(data.end.dateTime).getTime(),
    status: data.status,
    eventType: data.eventType,
  };
};

const toSubscription = (data: any): Subscription => {
  return {
    id: data.id,
    billingType: data.billing_type,
    billingPeriod: data.billing_period,
    initialPrice: {
      USD: data.initial_price.USD,
      EUR: data.initial_price.EUR,
    },
    recurringPrice: {
      USD: data.recurring_price.USD,
      EUR: data.recurring_price.EUR,
    },
    trialDays: data.trial_days,
  };
};

export const toPlan = (data: any): Plan => {
  return {
    uid: data.uid,
    name: data.name,
    config: data.config,
    subscription: data.subscription ? toSubscription(data.subscription) : null,
  };
};

export const toOrganization = (data: any): Organization => {
  return {
    uid: data.uid,
    name: data.name,
    plan: data.plans ? toPlan(data.plans) : ({} as Plan),
    subscriptionId: data.subscription_id,
    domains: data.organization_domains
      ? toOrganizationDomains(data.organization_domains)
      : [],
  };
};

export const toOrganizationDomains = (data: any): Array<string> => {
  return data.map((domain) => domain.domain);
};

export const toUserRole = (data: any): UserRole => {
  return {
    uid: data.uid,
    name: data.name,
    acls: new Ability(data.acls),
  };
};

export const toRole = (data: any): Role => {
  return {
    uid: data.uid,
    name: data.name,
    acls: data.acls,
  };
};

export const toProjectCategory = (data: any): ProjectCategory => {
  return {
    uid: data.uid,
    name: data.name,
    color: data.color,
  };
};

export const toCustomer = (data: any): Customer => {
  return {
    uid: data.uid,
    nominative: data.nominative,
    email: data.email,
    fiscalCode: data.fiscal_code,
    vatNumber: data.vat_number,
    externalId: data.external_id,
  };
};

const toTeamMember = (data: any): TeamMember => {
  return {
    uid: data.uid,
    email: data.email,
    nominative: data.nominative,
    imageUrl: data.image_url,
    userTags: (data.user_tags || "").split(","),
  };
};

export const toProject = (data: any): Project => {
  return new Project(
    data.uid,
    data.title,
    data.customer ? toCustomer(data.customer) : null,
    data.external_reference,
    data.not_billable,
    data.budget,
    data.marginability_percentage,
    data.tolerance_days,
    data.project_manager ? toUser(data.project_manager) : null,
    data.category ? toProjectCategory(data.category) : null,
    data.organization_id,
    data.status,
    data.project_updates ? data.project_updates.map(toProjectUpdates) : null,
    parseInt(data.total_work_log_cost) || 0,
    data.contact_name,
    data.contact_surname,
    data.contact_email,
    data.contact_role,
    data.start_date ? new Date(data.start_date) : null,
    data.end_date ? new Date(data.end_date) : null,
    // data.members ? data.members.map((member: any) => toUser(member)) : null,
    //data.members ? data.members : null,
    data.members ? data.members.map(toTeamMember) : null,
    data.description,
    parseInt(data.total_final_balance_expenses) || 0,
    parseInt(data.total_work_log) || 0,
    data.closing_date ? new Date(data.closing_date) : null,
    data.project_type,
    data.area ? toProjectArea(data.area) : null,
    data.progress ?? null
  );
};

export const toProjectReport = (data: any): ProjectReport => {
  return {
    title: data.title,
    startTime: parseInt(data.start_time),
    endTime: parseInt(data.end_time),
    elapsedTime: data.elapsed_time,
    user: toUser(data.user),
    linkTask: data.link_task,
    category: data.category,
    project: data.project,
  };
};

export const toProjectExpense = (data: any): Expense => {
  return new Expense(
    data.uid,
    data.organization_id,
    data.project_id,
    data.is_final_balance,
    data.supplier,
    data.title,
    data.description,
    data.amount,
    data.estimated_amount,
    data.confirmed_amount,
    data.transacation_date,
    data.is_billable,
    data.approver ? toUser(data.approver) : null
  );
};

export const toProjectStatstics = (data: any): ProjectStatstics => {
  return {
    budget: data.budget || 0,
    availableBudget: data.available_budget || 0,
    targetBudget: data.target_budget || 0,
    targetMarginabilityPercentage: data.target_marginability_percentage || 0,
    currentMarginalityPercentage: data.current_marginality_percentage || 0,
    totalExpenses: data.total_expenses || 0,
    totalConfirmedHoursCost: data.totalConfirmedHoursCost || 0,
    totalConfirmedHours: data.totalConfirmedHours || 0,
    totalUnconfirmedHoursCost: data.totalUnconfirmedHoursCost || 0,
    totalUnconfirmedHours: data.totalUnconfirmedHours || 0,
    totalPlannedHoursCost: data.totalPlannedHoursCost || 0,
    totalPlannedHours: data.totalPlannedHours || 0,
    currentBudgetUsed: data.current_budget_used || 0,
    cumulativeTotalCosts: data.cumulativeTotalCosts || [],
    costsForecast: data.costsForecast || [],
  };
};

export const toProjectNote = (data: any): ProjectNote => {
  return {
    uid: data.uid,
    note: data.note,
    createdAt: data.created_at,
    createdBy: toUser(data.created_by),
  };
};

export const toTimesheetByDayRow = (data: any): TimesheetRowByDay => {
  return {
    userId: data.user_id,
    nominative: data.nominative,
    workLogDate: new Date(data.work_log_date),
    workLog: parseInt(data.work_log) || 0,
    contractualHours: data.contractual_hours || 0,
    contractualHoursByDay: data.contractual_hours_by_day || {},
    contractType: data.contract_type || "full-time",
  };
};
export const isTimesheetByDayRow = (x: any): x is TimesheetRowByDay =>
  Object.keys(x).includes("contractType");

export const toTimesheetByProjectRow = (data: any): TimesheetRowByProject => {
  return {
    userId: data.user_id,
    nominative: data.nominative,
    workLogDate: new Date(data.work_log_date),
    workLog: parseInt(data.work_log) || 0,
    projectTitle: data.project_title || "",
    projectId: data.project_uid || "",
    customerNominative: data.customer_nominative || "",
  };
};
export const isTimesheetByProjectRow = (x: any): x is TimesheetRowByProject =>
  Object.keys(x).includes("projectTitle");

export const toTimesheetByClientRow = (data: any): TimesheetRowByClient => {
  return {
    userId: data.user_id,
    nominative: data.nominative,
    workLogDate: new Date(data.work_log_date),
    workLog: parseInt(data.work_log) || 0,
    customerNominative: data.customer_nominative || "",
  };
};

export const toTimer = (data: any): Timer => {
  return new Timer(
    data.user_id,
    data.start_time,
    toEvent(data.time_blocking_event)
  );
};

export const toTask = (data: any): Task => {
  return {
    uid: data.uid,
    name: data.name,
    description: data.description,
    dueDate: data.due_date ? parseInt(data.due_date) : null,
    color: data.color,
    complete: !!data.complete,
    user: toUser(data.user),
    project: data.project,
    organizationId: data.organization_id,
    parentTask: data.parent_task ?? undefined,
    subTasks: data.sub_tasks
      ? data.sub_tasks.map((subTask) => toTask(subTask))
      : undefined,
    priority: data.priority,
    category: data.category ? toTaskCategory(data.category) : null,
    activityHours: data.total_work_time ? data.total_work_time.hours : 0,
  };
};

export const toTeamReportRow = (data: any): TeamReportRow => {
  return {
    userId: data.uid,
    nominative: data.nominative,
    imageUrl: data.image_url,
    contractualHours: parseInt(data.contractual_hours) || 0,
    plannedTime: parseInt(data.planned_time),
    workLog: parseInt(data.worklog),
    billableWorkLog: parseInt(data.billable_worklog),
    taskCategoriesReport: data.task_categories_report.map(
      (taskCatgoryReport: any) => ({
        categoryName: taskCatgoryReport.category_name,
        color: taskCatgoryReport.color,
        elapsedTime: taskCatgoryReport.elapsed_time,
      })
    ),
  };
};

export const toTaskCategory = (data: any): TaskCategory => {
  return {
    uid: data.uid,
    name: data.name,
    color: data.color,
    isUsedForTasks: !!data["is_used_for_tasks"],
    isUsedForPlanning: !!data["is_used_for_planning"],
  };
};

export const toProjectStatus = (data: any): ProjectStatus => ({
  uid: data.uid,
  key: data.key,
  name: data.name,
  editable: data.editable,
  selectedByDefault: data.selected_by_default,
});

export const toWebhook = (data: any): Webhook => ({
  uid: data.uid,
  description: data.description,
  enabled: data.enabled,
  events: data.events.split(","),
  url: data.url,
  conditions: data.conditions,
});

export const toUserContract = (data: any): UserContract => ({
  uid: data.uid,
  contractType: data.contract_type,
  contractualHours: data.contractual_hours || 0,
  contractualHoursByDay: data.contractual_hours_by_day || {},
  hourlyCost: data.hourly_cost,
  productivityTarget: data.productivity_target,
  fromDate: new Date(data.from_date),
  toDate: new Date(data.to_date),
});

export const toProjectLinks = (data: any): ProjectLinks => ({
  linkId: data.linkid,
  title: data.title,
  url: data.url,
});

export const toProjectUpdates = (data: any): ProjectUpdate => ({
  updateId: data.updateid,
  data: {
    priorities: data.priorities,
    roadblocks: data.roadblocks,
    update: data.update,
  },
  date:
    typeof data.created_at === "string"
      ? new Date(data.created_at)
      : data.created_at,
  status: data.status,
  progress: data.progress ?? null,
});

export const toOverhead = (data: any): Overhead => {
  return {
    uid: data.uid,
    amount: data.amount,
    fromDate: new Date(data.from_date),
    toDate: new Date(data.to_date),
  };
};

export const toProjectArea = (data: any): ProjectArea => {
  return {
    uid: data.uid,
    name: data.name,
  };
};
