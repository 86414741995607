import { makeAutoObservable } from "mobx";
import api from "../services/api";
import {
  Customer,
  EditedProject,
  EditedProjectExpense,
  EditedProjectExpenseBeta,
  EditedProjectNote,
  Organization,
  ProjectArea,
  ProjectCategory,
  ProjectNote,
  ProjectStatstics,
  ProjectStatus,
  ProjectUpdate,
  TeamMember,
  User,
} from "../types";
import Expense from "./Expense";

export default class Project {
  uid: string;
  title: string;
  customer: Customer | null;
  externalReference: string | null;
  notBillable: boolean;
  budget: number | null;
  marginabilityPercentage: number | null;
  toleranceDays: number | null;
  projectManager: User | null;
  category: ProjectCategory | null;
  area: ProjectArea | null;
  organizationId: Organization["uid"];
  expenses: Expense[];
  status: ProjectStatus;
  projectUpdates: ProjectUpdate[];
  notes: ProjectNote[];
  totalWorkLogCost: number;
  contactName: string | null;
  contactSurname: string | null;
  contactEmail: string | null;
  contactRole: string | null;
  startDate: Date | null;
  endDate: Date | null;
  closingDate: Date | null;
  projectType: string | null;
  member: TeamMember[] | null;
  description: string | null;
  totalFinalBalanceExpenses: number;
  totalWorkLog: number;
  progress: number | null;
  projectStatstics: ProjectStatstics | undefined;

  isFetchingExpenses: boolean = false;
  isFetchingNotes: boolean = false;
  isSubmitting: boolean = false;
  isFetchProjectStatstics: boolean = false;

  constructor(
    uid: string,
    title: string,
    customer: Customer | null,
    externalReference: string | null,
    notBillable: boolean,
    budget: number | null,
    marginabilityPercentage: number | null,
    toleranceDays: number | null,
    projectManager: User | null,
    category: ProjectCategory | null,
    organizationId: Organization["uid"],
    status: ProjectStatus,
    projectUpdates: ProjectUpdate[],
    totalWorkLogCost: number,
    contact_name: string | null,
    contact_surname: string | null,
    contact_email: string | null,
    contact_role: string | null,
    startDate: Date | null,
    endDate: Date | null,
    member: TeamMember[] | null,
    description: string | null,
    totalFinalBalanceExpenses: number,
    totalWorkLog: number,
    closingDate: Date | null,
    project_type: string | null,
    area: ProjectArea | null,
    progress: number | null
  ) {
    this.uid = uid;
    this.title = title;
    this.customer = customer;
    this.externalReference = externalReference;
    this.notBillable = notBillable;
    this.budget = budget;
    this.marginabilityPercentage = marginabilityPercentage;
    this.toleranceDays = toleranceDays;
    this.projectManager = projectManager;
    this.category = category;
    this.organizationId = organizationId;
    this.expenses = [];
    this.projectStatstics = undefined;
    this.status = status;
    this.projectUpdates = projectUpdates;
    this.notes = [];
    this.totalWorkLogCost = totalWorkLogCost;
    this.contactName = contact_name;
    this.contactSurname = contact_surname;
    this.contactEmail = contact_email;
    this.contactRole = contact_role;
    this.startDate = startDate;
    this.endDate = endDate;
    // this.members = members;
    this.member = member;
    this.description = description;
    this.totalFinalBalanceExpenses = totalFinalBalanceExpenses;
    this.totalWorkLog = totalWorkLog;
    this.closingDate = closingDate;
    this.projectType = project_type;
    this.area = area;
    this.progress = progress;

    makeAutoObservable(this);
  }

  get currentMarginability() {
    return this.budget && this.budget > 0
      ? (
          100 -
          (((this.totalWorkLogCost || 0) + this.totalFinalBalanceExpenses ||
            0) /
            (this.budget || 0)) *
            100
        ).toFixed(2)
      : null;
  }

  updateInstance = (data) => {
    console.log("udpate_instace \n", data);

    this.title = data.title;
    this.customer = data.customer;
    this.externalReference = data.externalReference;
    this.notBillable = data.notBillable;
    this.budget = data.budget;
    this.marginabilityPercentage = data.marginabilityPercentage;
    this.toleranceDays = data.toleranceDays;
    this.category = data.category;
    this.status = data.status;
    // this.projectUpdates = data.projectUpdates;
    this.contactName = data.contactName;
    this.contactSurname = data.contactSurname;
    this.contactEmail = data.contactEmail;
    this.contactRole = data.contactRole;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.projectManager = data.projectManager;
    // this.members = data.members;
    this.member = data.member;
    this.description = data.description;
    this.totalFinalBalanceExpenses =
      data.totalFinalBalanceExpenses || this.totalFinalBalanceExpenses;
    this.totalWorkLogCost = data.totalWorkLogCost || this.totalWorkLogCost;
    this.totalWorkLog = data.totalWorkLog;
    this.closingDate = data.closingDate;
    this.projectType = data.projectType;
  };

  update = async (editedProject: EditedProject) => {
    this.isSubmitting = true;

    try {
      const data = await api.updateProject(
        this.organizationId,
        this.uid,
        editedProject
      );

      this.updateInstance(data);
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  refresh = async () => {
    const data = await api.fetchProject(this.organizationId, this.uid);
    console.log("fetch-project-regresh \n", data);

    this.updateInstance(data);
  };

  updateDescription = async (description: Project["description"]) => {
    const editedProject: EditedProject = {
      uid: this.uid,
      title: this.title,
      customerId: this.customer?.uid || null,
      externalReference: this.externalReference,
      notBillable: this.notBillable,
      budget: this.budget,
      marginabilityPercentage: this.marginabilityPercentage,
      toleranceDays: this.toleranceDays,
      projectManagerId: this.projectManager?.uid || "",
      categoryId: this.category?.uid || null,
      areaId: this.area?.uid || null,
      statusId: this.status.uid,
      contactName: this.contactName,
      contactSurname: this.contactSurname,
      contactEmail: this.contactEmail,
      contactRole: this.contactRole,
      startDate: this.startDate,
      closingDate: this.closingDate,
      endDate: this.endDate,
      description,
      salesAccount: this.member?.length
        ? this.member.filter((e) => e.userTags.includes("sales")).length
          ? this.member
              .filter((e) => e.userTags.includes("sales"))
              .map((e) => e.uid)
          : []
        : [],
      responsible: this.member?.length
        ? this.member.filter((e) => e.userTags.includes("responsible")).length
          ? this.member
              .filter((e) => e.userTags.includes("responsible"))
              .map((e) => e.uid)
          : []
        : [],
      accountable: this.member?.length
        ? this.member.filter((e) => e.userTags.includes("accountable")).length
          ? this.member
              .filter((e) => e.userTags.includes("accountable"))
              .map((e) => e.uid)
          : []
        : [],
      consulted: this.member?.length
        ? this.member.filter((e) => e.userTags.includes("consulted")).length
          ? this.member
              .filter((e) => e.userTags.includes("consulted"))
              .map((e) => e.uid)
          : []
        : [],
      informed: this.member?.length
        ? this.member.filter((e) => e.userTags.includes("informed")).length
          ? this.member
              .filter((e) => e.userTags.includes("informed"))
              .map((e) => e.uid)
          : []
        : [],
      projectType: this.projectType,
    };

    await this.update(editedProject);
  };

  fetchExpenses = async () => {
    this.isFetchingExpenses = true;

    try {
      const expenses = await api.fetchProjectExpenses(
        this.organizationId,
        this.uid
      );
      this.expenses = expenses;
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingExpenses = false;
    }
  };

  fetchProjectStatstics = async (project_id, orgeanization_id, user_id) => {
    this.isFetchProjectStatstics = true;
    if (project_id && orgeanization_id && user_id) {
      try {
        const projectStatstics = await api.fetchProjectStatsticsReport(
          orgeanization_id,
          project_id,
          user_id
        );
        this.projectStatstics = projectStatstics;
      } catch (err) {
        throw err;
      } finally {
        this.isFetchProjectStatstics = false;
      }
    } else {
      this.isFetchProjectStatstics = false;
    }
  };

  submitExpense = async (editedExpense: EditedProjectExpense) => {
    this.isSubmitting = true;
    try {
      if (editedExpense.uid) {
        const expense = await api.updateProjectExpense(
          this.organizationId,
          this.uid,
          editedExpense
        );

        for (let i = 0; i < this.expenses.length; i++) {
          if (this.expenses[i].uid === expense.uid) {
            this.expenses[i] = { ...expense };
          }
        }
      } else {
        const expense = await api.addProjectExpense(
          this.organizationId,
          this.uid,
          editedExpense
        );

        this.expenses.push(expense);
      }
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
      this.refresh();
    }
  };

  // for the new version of expense add
  submitExpenseBeta = async (editedExpense: EditedProjectExpenseBeta) => {
    this.isSubmitting = true;
    try {
      if (editedExpense.uid) {
        const expense = await api.updateProjectExpenseBeta(
          this.organizationId,
          this.uid,
          editedExpense
        );

        for (let i = 0; i < this.expenses.length; i++) {
          if (this.expenses[i].uid === expense.uid) {
            this.expenses[i] = { ...expense };
          }
        }
      } else {
        const expense = await api.addProjectExpenseBeta(
          this.organizationId,
          this.uid,
          editedExpense
        );

        this.expenses.push(expense);
      }
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
      this.refresh();
    }
  };

  deleteExpense = async (expense: Expense) => {
    this.isSubmitting = true;
    try {
      await api.deleteProjectExpense(
        this.organizationId,
        this.uid,
        expense.uid
      );

      this.expenses = this.expenses.filter((exp) => exp.uid !== expense.uid);
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
      this.refresh();
    }
  };

  fetchNotes = async () => {
    this.isFetchingNotes = true;

    try {
      const notes = await api.fetchProjectNotes(this.organizationId, this.uid);
      this.notes = notes;
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingNotes = false;
    }
  };

  submitNote = async (editedNote: EditedProjectNote) => {
    this.isSubmitting = true;
    try {
      if (editedNote.uid) {
        const note = await api.updateProjectNote(
          this.organizationId,
          this.uid,
          editedNote
        );
        for (let i = 0; i < this.notes.length; i++) {
          if (this.notes[i].uid === note.uid) {
            this.notes[i] = { ...note };
          }
        }
      } else {
        const note = await api.addProjectNote(
          this.organizationId,
          this.uid,
          editedNote
        );

        this.notes.push(note);
      }
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  deleteNote = async (note: ProjectNote) => {
    this.isSubmitting = true;
    try {
      await api.deleteProjectNote(this.organizationId, this.uid, note.uid);

      this.notes = this.notes.filter((exp) => exp.uid !== note.uid);
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };
}
