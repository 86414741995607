import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import "../../libs/@fullcalendar/common";
import FullCalendar from "../../libs/@fullcalendar/react";
// import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "../../libs/@fullcalendar/timegrid";
import interactionPlugin from "../../libs/@fullcalendar/interaction";
import googleCalendarPlugin from "../../libs/@fullcalendar/google-calendar";
import moment from "moment";
import { Observer, observer } from "mobx-react";
import { useStores } from "../../hooks/useStores";
import {
  useLocation,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import navigationUtils from "../../utils/navigation";
import { DeleteRecurrenceActions, FixMeLater } from "../../types";
import { EventTile } from "../../components/EventTile";
import { Menu, MenuItem } from "../../components/ContextMenu";
import { EventForm } from "../../components/EventForm";
import { sec2time } from "../../utils/dateTimeFormat";
import { EventClickArg } from "../../libs/@fullcalendar/common/interactions/EventClicking";
import { DateSelectArg } from "../../libs/@fullcalendar/common/calendar-utils";
import { DayHeaderContentArg } from "../../libs/@fullcalendar/common/render-hook-misc";
import styled from "styled-components";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/modal";
import { SettingsButton } from "./styled";
import { Icon } from "../../components/Icon";
import { Select } from "../../components/Select";
import { Radio, RadioGroup } from "@chakra-ui/radio";
import { HStack, Stack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { DeleteEventDialog } from "../../components/DeleteEventDialog";
import { SecondarySidebarContainer } from "../Sidebar/styled";
import { EventsSource } from "../EventsSource";
import { Portal } from "../../components/Portal/Portal";
import { BoardSettingsModal } from "../../components/BoardSettingsModal";
import dayjs from "dayjs";
import { Spacer, Flex, Box } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { MdTaskAlt, MdClose } from "react-icons/md";
import { MenuItemKeys } from "../Sidebar";
import { useUserProjects } from "../../hooks/useUserProjects";

enum RecurrenceEditAction {
  onlyThis,
  thisAndFollowingOnes,
}

type Props = {};

export const Board: React.FC<Props> = observer(() => {
  const { board, session, organization, ui } = useStores();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const calendarRef = useRef<FullCalendar | null>(null);

  const [currentWeek, setCurrentWeek] = useState<dayjs.Dayjs>();

  const [isModalEventVisible, setIsModalEventVisible] = useState(false);

  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const [eventData, setEventData] = useState<FixMeLater>(null);
  const [editRecurrentEvent, setEditRecurrentEvent] = useState<FixMeLater>(
    null
  );
  const [editRecurrenceAction, setEditRecurrenceAction] = useState(
    RecurrenceEditAction.onlyThis
  );
  const [
    isModalEditRecurringEventVisible,
    setIsModalEditRecurringEventVisible,
  ] = useState(false);
  // https://fullcalendar.io/docs/eventDrop
  const [editEventInfo, setEditEventInfo] = useState<any>(null);
  const [isOpenDeleteEventDialog, setIsOpenDeleteEventDialog] = React.useState(
    false
  );
  const [eventToDelete, setEventToDelete] = useState<any>(null);

  const {
    // isLoading: isFetchingUserProjects,
    data: userProjects = [],
  } = useUserProjects(
    session.user?.organization?.uid || "",
    session.user?.uid || ""
  );

  useEffect(() => {
    board.init();
    if (calendarRef.current) {
      const api = calendarRef.current.getApi();
      api.today();
      if (session.user?.preferences) {
        const isWeek = session.user.preferences.calendarView;
        if (isWeek === "timeGridDay") {
          setCurrentWeek(dayjs(api.getDate()));
        } else {
          setCurrentWeek(dayjs().startOf("week"));
        }
      }
    }
  }, []);

  useEffect(() => {
    if (session.user?.organization) {
      organization.fetchUsers(session.user.organization.uid, true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (session.user && session.user.preferences.calendarView) {
      const { current } = calendarRef;
      if (current) {
        const calendarAPI = current.getApi();
        if (calendarAPI?.changeView) {
          calendarAPI.changeView(session.user.preferences.calendarView);
        }
      }
    }
  }, [session.user]);

  const organizationUsers = useMemo(() => {
    return organization.users
      ?.map(
        (user) =>
          ({
            value: user.uid,
            label: user.nominative,
          } || null)
      )
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [organization.users]);

  const activeUser = useMemo(() => {
    if (!userId || !organization.users) {
      return null;
    }

    const userData = organization.users.find((user) => user.uid === userId);

    return {
      value: userId,
      label: userData?.nominative,
    };
  }, [userId, organization.users]);

  const handleCancelDeleteEventDialog = useCallback(
    () => setIsOpenDeleteEventDialog(false),
    [setIsOpenDeleteEventDialog]
  );

  const handleDateRangeChange = useCallback(
    (dateInfo) => {
      const userId = navigationUtils.fromRoutes.userId(location.pathname);
      if (userId) {
        board.changeDateRange(userId, dateInfo.start, dateInfo.end);
      }
    },
    [board, location]
  );

  const handleDateSelect = useCallback(
    (selectInfo: DateSelectArg) => {
      setEventData({
        startTime: selectInfo.start,
        endTime: selectInfo.end,
      });
      setIsModalEventVisible(true);
    },
    [setIsModalEventVisible, setEventData]
  );

  const handleEventClick = useCallback(
    (clickInfo: EventClickArg) => {
      console.log("handleEventClick -> ", clickInfo);
      /*
       * If user click on GCalendar event and edit it, then create a new task
       * (so the Id must be null)
       */
      setEventData({
        title: clickInfo.event.title,
        startTime: clickInfo.event.start,
        endTime: clickInfo.event.end,
        color: clickInfo.event.backgroundColor,
        id: clickInfo.event.extendedProps?.isTask ? clickInfo.event.id : null,
        extendedProps: clickInfo.event.extendedProps,
        source: clickInfo.event.extendedProps?.source,
        sourceId: clickInfo.event.extendedProps?.source
          ? clickInfo.event.extendedProps?.sourceId
          : null,
        recurrence: clickInfo.event.extendedProps?.recurrence || null,
      });

      setIsModalEventVisible(clickInfo?.event.id !== session.timer?.event.uid);
      // if (
      //   window.confirm(
      //     `Are you sure you want to delete the event '${clickInfo.event.title}'`
      //   )
      // ) {
      //   clickInfo.event.remove();
      // }
    },
    [setEventData, setIsModalEventVisible, session, organization]
  );

  const handleSubmitEvent = useCallback(
    async (data) => {
      try {
        await board.submitEvent(data);
      } catch (err) {
        alert(JSON.stringify(err));
      } finally {
        setIsModalEventVisible(false);
      }
    },
    [board, setIsModalEventVisible]
  );

  // const handleEditRecurringEvent = useCallback(() => {
  //   console.log({ editRecurrenceAction, editEventInfo });
  // }, [editRecurrenceAction, editEventInfo]);

  // const handleCancelEditRecurringEvent = useCallback(() => {
  //   setIsModalEditRecurringEventVisible(false);
  //   console.log(editEventInfo);
  //   console.log(board.events);
  // }, [setIsModalEditRecurringEventVisible, editEventInfo, board]);

  // https://fullcalendar.io/docs/eventDrop
  const handleResizeEvent = useCallback(
    async (eventDropInfo) => {
      console.log("handleResizeEvent -> ", eventDropInfo);
      let event: any = {
        title: eventDropInfo.event.title,
        startTime: eventDropInfo.event.start,
        endTime: eventDropInfo.event.end,
        color: eventDropInfo.event.backgroundColor,
        id: eventDropInfo.event.id,
        elapsedTime: eventDropInfo.event.extendedProps.elapsedTime,
      };

      if (
        (eventDropInfo.event.extendedProps.recurrenceId &&
          eventDropInfo.event.id === "null") ||
        (eventDropInfo.event.extendedProps?.source === "gcalendar" &&
          !eventDropInfo.event.extendedProps?.isTask)
      ) {
        // await board.updateGoogleCelandar(eventDropInfo.event.extendedProps.calanderId, new Date(eventDropInfo.event.start), new Date(eventDropInfo.event.end), eventDropInfo.event.id)

        // this do comment by @jigo
        // // setEditEventInfo(eventDropInfo);
        // // setIsModalEditRecurringEventVisible(true);
        event = {
          id: null, // Create new task
          title: eventDropInfo.event.title,
          startTime: eventDropInfo.event.start,
          endTime: eventDropInfo.event.end,
          elapsedTime: eventDropInfo.event.extendedProps.elapsedTime,
          color: eventDropInfo.event.backgroundColor,
          extendedProps: eventDropInfo.event.extendedProps,
          source: eventDropInfo.event.extendedProps?.source,
          eventType: eventDropInfo.event.extendedProps?.eventType,
          sourceId: eventDropInfo.event.extendedProps?.source ? event.id : null,
          recurrenceId: eventDropInfo.event.extendedProps?.recurrenceId || null,
          gEventId: eventDropInfo.event.id,
          calanderId: eventDropInfo.event.extendedProps?.calanderId,
        };
      }
      // // here GC has the event and we take the task as the task update then GC event also update
      // else if (eventDropInfo.event.extendedProps?.source === "gcalendar") {
      //   if (eventDropInfo.event.extendedProps.calanderId) {
      //     // alert(JSON.stringify(eventDropInfo.event))
      //     await board.updateGoogleCelandar(
      //       eventDropInfo.event.extendedProps.calanderId,
      //       new Date(eventDropInfo.event.start),
      //       new Date(eventDropInfo.event.end),
      //       eventDropInfo.event.extendedProps.sourceId,
      //       eventDropInfo.event.title
      //     );
      //   } else {
      //     alert("event Id Not found!");
      //   }
      // }

      try {
        // It's a recurring event and is changed the date, so we need to update the recurrence to skip the old date
        if (eventDropInfo.event.extendedProps.recurrenceId) {
          await board.updateRecurrence(
            eventDropInfo.event.extendedProps.recurrenceId,
            {
              skip: eventDropInfo.oldEvent.start.getTime(),
            },
            false
          );
        }
        await board.submitEvent(event);
      } catch (err) {
        alert(JSON.stringify(err));
      }
    },
    [board]
  );

  const handleCancelModal = useCallback(() => {
    setEventData(null);
    setIsModalEventVisible(false);
  }, [setEventData, setIsModalEventVisible]);

  const handleConfirmDeleteEvent = useCallback(
    (event: any) => {
      setEventToDelete(event);
      setIsOpenDeleteEventDialog(true);
    },
    [setEventToDelete, setIsOpenDeleteEventDialog]
  );

  const handleDeleteEvent = useCallback(
    async (event: any, deleteRecurrence?: DeleteRecurrenceActions) => {
      try {
        if (event) {
          // Recurring event instance
          if (event.id === "null" && event.extendedProps.recurrenceId) {
            const recurrenceData: { skip?: number; end_date?: number } = {};

            if (
              deleteRecurrence === DeleteRecurrenceActions.ONLY_CURRENT_EVENT
            ) {
              recurrenceData.skip = event.startTime.getTime();
            } else if (
              deleteRecurrence === DeleteRecurrenceActions.ALL_RECURRENT_EVENTS
            ) {
              recurrenceData.end_date = event.startTime - 1000;
            }

            await board.updateRecurrence(
              event.extendedProps.recurrenceId,
              recurrenceData
            );
            // delete the gCalendar events
          } else {
            await board.deleteEvent(event.id, !!deleteRecurrence);
            // if (
            //   event.extendedProps.source === "gcalendar" &&
            //   event.extendedProps.calanderId &&
            //   event.extendedProps.sourceId
            // ) {
            //   await board.deleteGoogleCalendarEvent(
            //     event.extendedProps.calanderId,
            //     event.extendedProps.sourceId
            //   );
            // } else {
            //   // alert('fails to delete event in GC!')
            // }
          }
        }
      } catch (err) {
        console.log("err event delete-<\n ", err);

        // alert(JSON.stringify(err) + "event delete");
      } finally {
        setEventData(null);
        setEventToDelete(null);
        setIsOpenDeleteEventDialog(false);
        setIsModalEventVisible(false);
      }
    },
    [board, setEventData, setIsModalEventVisible]
  );

  const handleTimerStop = useCallback(async () => {
    try {
      await session.stopTimer();
      // await board.submitEvent({
      //   id,
      //   elapsedTime: currentElapsedTime,
      // });
    } catch (err) {
      console.log(err);
      alert(JSON.stringify(err));
    }
  }, [session]);

  const handleTimerStart = useCallback(
    async (eventId) => {
      if (session.timer !== null) {
        if (
          window.confirm(
            `Sei sicuro di voler avviare il time tracking su questo blocco e fermare gli altri attivi?`
          )
        ) {
          // const currentTimer = new Date().getTime();
          // if (board.eventTimerPlayingStart) {
          //   handleTimerStop(
          //     board.eventTimerPlayingId,
          //     (currentTimer - board.eventTimerPlayingStart.getTime()) / 1000 +
          //       (board.currentPlayingEvent?.elapsedTime || 0)
          //   );
          // }
          // board.eventTimerPlayingId = eventId;
          // board.eventTimerPlayingStart = new Date();
          await handleTimerStop();
        }
      }
      board.startTimer(eventId);
    },
    [board, session, handleTimerStop]
  );

  const handleUpdateUserPreferences = useCallback(
    (preferences) => {
      session.updateUser({ preferences });
    },
    [session]
  );

  const handleSubmitInlineEvent = useCallback((data) => console.log(data), []);

  // this the callback is use when any event was the drop events.
  const handleReceiveEvent = useCallback(
    async (data) => {
      console.log("handleReceiveEvent -> ", data);
      const { title, start, extendedProps } = data.event;

      const end = new Date();
      end.setTime(start.getTime() + 1 * 60 * 60 * 1000); // on D&D event, end time is null
      await board.submitEvent({
        title,
        startTime: start,
        endTime: end, // on D&D event, end time is null
        source: extendedProps.source,
        sourceId: extendedProps.eventSourceId,
        eventType: "activity",
        color: extendedProps.eventColor || null,
        projectId: extendedProps.projectId || null,
        categoryId: extendedProps.categoryId || null,
        taskLinkId: extendedProps.taskLinkId || null,
      });
      data.revert(); // Revert to delete the event create on calendar by the D&D
    },
    [board]
  );

  // this called when the click on the edit menu option click.
  const handleConfirmEventTime = useCallback(
    async (event) => {
      /*
       * If user click on GCalendar event and edit it, then create a new task
       * (so the Id must be null)
       */
      // alert(JSON.stringify(event));
      // return;
      if (!event.extendedProps.isTask || event.id === "null") {
        await board.submitEvent({
          id: null, // Create new task
          title: event.title,
          startTime: event.start,
          endTime: event.end,
          elapsedTime: (event.end.getTime() - event.start.getTime()) / 1000,
          color: event.backgroundColor,
          extendedProps: event.extendedProps,
          source: event.extendedProps?.source,
          sourceId: event.extendedProps?.source ? event.id : null,
          recurrenceId: event.extendedProps?.recurrenceId || null,
          completed: true,
        });
      } else {
        await board.submitEvent({
          id: event.id,
          elapsedTime: (event.end.getTime() - event.start.getTime()) / 1000,
          completed: true,
        });
      }
    },
    [board]
  );

  const handleEventCompleted = useCallback(
    async (event) => {
      /*
       * If user click on GCalendar event and edit it, then create a new task
       * (so the Id must be null)
       */
      if (!event.extendedProps.isTask || event.id === "null") {
        alert("event id null");
        await board.submitEvent({
          id: null, // Create new task
          title: event.title,
          startTime: event.start,
          endTime: event.end,
          elapsedTime: (event.end.getTime() - event.start.getTime()) / 1000,
          color: event.backgroundColor,
          completed: true,
          extendedProps: event.extendedProps,
          source: event.extendedProps?.source,
          sourceId: event.extendedProps?.source ? event.id : null,
          recurrenceId: event.extendedProps?.recurrenceId || null,
        });
      } else {
        await board.submitEvent({
          id: event.id,
          elapsedTime: (event.end.getTime() - event.start.getTime()) / 1000,
          completed: true,
        });
      }
    },
    [board]
  );

  const handleChangeEventTypes = useCallback(
    async (event) => {
      if (event.extendedProps?.eventType === "planning") {
        await board.submitEvent({
          id: event.id,
          elapsedTime: event.eventDropInfo?.elapsedTime,
          eventType: "activity",
        });
      } else if (event.extendedProps?.eventType === "activity") {
        await board.submitEvent({
          id: event.id,
          eventType: "planning",
        });
      } else {
        console.log("event -> ", event);

        alert("somthing worng-changeeventType");
      }
    },
    [board]
  );

  const handleEditEvent = useCallback(
    (eventInfo) => {
      console.log("handleEditEvent -> ", eventInfo);
      // TODO: Refactor
      const event = {
        title: eventInfo.event.title,
        startTime: eventInfo.event.start,
        endTime: eventInfo.event.end,
        color: eventInfo.event.backgroundColor,
        id: eventInfo.event.id,
        elapsedTime: eventInfo.event.extendedProps.elapsedTime,
        projectId: eventInfo.event.extendedProps.projectId,
      };
      setEventData(event);
      setIsModalEventVisible(true);
    },
    [setEventData, setIsModalEventVisible]
  );

  const handleCopyEvent = useCallback(
    (eventInfo) => {
      // TODO: Refactor
      const event = {
        title: eventInfo.event.title,
        startTime: eventInfo.event.start,
        endTime: eventInfo.event.end,
        color: eventInfo.event.backgroundColor,
        elapsedTime: eventInfo.event.extendedProps.elapsedTime,
        projectId: eventInfo.event.extendedProps.projectId,
        iaDuplicate: true,
        extendedProps: eventInfo.event.extendedProps,
      };
      setEventData(event);
      setIsModalEventVisible(true);
    },
    [setEventData, setIsModalEventVisible]
  );

  const getDayWorkLog = (day: Date) => {
    const dailyEvents = board.timeBlockingEvents.filter((event) => {
      const eventStartDate = new Date(event.startTime);
      eventStartDate.setHours(0, 0, 0, 0);
      return (
        eventStartDate.getTime() === day.getTime() &&
        event.eventType === "activity" &&
        event.elapsedTime !== 0 &&
        event.elapsedTime !== null &&
        event.completed
      );
    });
    const workLog = dailyEvents.reduce((prevValue, currValue) => {
      return prevValue + Number(currValue.elapsedTime);
    }, 0);
    return sec2time(workLog, "hm");
  };

  // total plan hours
  // const getDayPlannedHours = (day: Date) => {
  //   const dailyEvents = board.timeBlockingEvents.filter((event) => {
  //     const eventStartDate = new Date(event.startTime);
  //     eventStartDate.setHours(0, 0, 0, 0);
  //     return (
  //       eventStartDate.getTime() === day.getTime() &&
  //       event.eventType === "planning"
  //     );
  //   });
  //   const plannedHours = dailyEvents.reduce((prevValue, currValue) => {
  //     return prevValue + currValue.elapsedTime;
  //   }, 0);
  //   return sec2time(plannedHours, "HH:MM");
  // };

  if (!session.isLogged) {
    return <Navigate to={"/"} />;
  }

  const renderEventContent = (eventInfo) => {
    const menu = (
      <Portal className={"menu-portal"}>
        <Menu
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {!eventInfo.event.extendedProps.completed &&
            new Date(eventInfo.event.extendedProps.endDate).getTime() <
              new Date().getTime() &&
            !(eventInfo.event.extendedProps.eventType === "planning") &&
            (eventInfo.event.extendedProps.elapsedTime === null ||
              eventInfo.event.extendedProps.elapsedTime === undefined ||
              eventInfo.event.extendedProps.elapsedTime === 0) &&
            eventInfo.event.extendedProps.projectId && (
              <MenuItem
                // onMouseDown={() => { handleConfirmEventTime(eventInfo) }}
                onMouseDown={() => {
                  handleConfirmEventTime(eventInfo.event);
                }}
              >
                {t<string>("eventTile.actions.confirmTime")}
              </MenuItem>
            )}
          {/* {!eventInfo.event.extendedProps.completed &&
            !(eventInfo.event.extendedProps.evenType === "planning") && (
              <MenuItem
                onMouseDown={() => handleEventCompleted(eventInfo.event)}
              >
                {t<string>("eventTile.actions.completed")}
              </MenuItem>
            )} */}
          <MenuItem onMouseDown={() => handleEditEvent(eventInfo)}>
            {t<string>("eventTile.actions.edit")}
          </MenuItem>
          {!eventInfo.event.extendedProps.completed && (
            <MenuItem
              onMouseDown={() => handleChangeEventTypes(eventInfo.event)}
            >
              {eventInfo.event.extendedProps.eventType === "planning"
                ? t<string>("eventTile.actions.planningToActivty")
                : t<string>("eventTile.actions.activityToPlanning")}
            </MenuItem>
          )}
          <MenuItem onMouseDown={() => handleCopyEvent(eventInfo)}>
            {t<string>("eventTile.actions.copy")}
          </MenuItem>
        </Menu>
      </Portal>
    );

    const handleContextMenu = (e) => {
      const menuPortal = document.getElementsByClassName(
        "menu-portal"
      ) as HTMLCollectionOf<HTMLElement>;

      if (menuPortal && menuPortal[0]) {
        menuPortal[0].style.left = `${e.clientX}px`;
        menuPortal[0].style.top = `${e.clientY}px`;
      }
    };
    return (
      <Observer>
        {() => (
          <EventTile
            id={eventInfo.event.id}
            title={
              eventInfo.event.title === "null" || !eventInfo.event.title
                ? t("screens.board.formEventUntitledTitle")
                : eventInfo.event.title
            }
            elapsedTime={parseInt(eventInfo.event.extendedProps.elapsedTime)}
            startDate={new Date(eventInfo.event.start)}
            endDate={new Date(eventInfo.event.end)}
            event={eventInfo}
            isTimerRunning={
              (eventInfo.event.id === session.timer?.event.uid &&
                !!session.timer) ||
              false
            }
            completed={eventInfo.event.extendedProps.completed}
            onTimerStop={handleTimerStop}
            onTimerStart={handleTimerStart}
            onEventClick={handleEventClick}
            // isNew={
            //   eventInfo.event.extendedProps &&
            //   eventInfo.event.extendedProps.isNew
            // }
            onSubmit={handleSubmitInlineEvent}
            menu={
              eventInfo.event.extendedProps?.gEventId === undefined
                ? menu
                : null
            }
            eventType={eventInfo.event.extendedProps.eventType}
            source={eventInfo.event.extendedProps.source}
            onContextMenu={handleContextMenu}
          />
        )}
      </Observer>
    );
  };

  const renderHeadingDayCell = useCallback(
    (date: Date, isToday: boolean) => {
      return (
        <section
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <HeadingDayCell isToday={isToday}>{date.getDate()}</HeadingDayCell>
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DayCellSubtitle>
              {t<string>(`dayOfWeek.${date.getDay()}`)}
            </DayCellSubtitle>
            <span
              style={{
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {getDayWorkLog(date)}
              {/* /{" "}
           <span style={{ fontWeight: 400 }}>{getDayPlannedHours(date)}</span> */}
            </span>
          </section>
        </section>
      );
    },
    [calendarRef, currentWeek]
  );

  const showMonth = () => {
    if (currentWeek) {
      if (session.user?.preferences) {
        const isWeek = session.user.preferences.calendarView;
        if (isWeek === "timeGridDay") {
          return (
            t(`months.${currentWeek.month()}`) +
            ", " +
            currentWeek.date() +
            " " +
            `${currentWeek.format("YYYY")}`
          );
        } else {
          const startMomth = dayjs(currentWeek).startOf("week");
          const endMomth = dayjs(startMomth.add(7, "day"));
          if (startMomth.month() === endMomth.month()) {
            return t(`months.${currentWeek.month()}`);
          } else {
            return (
              t(`months.${startMomth.month()}`) +
              "-" +
              t(`months.${endMomth.month()}`)
            );
          }
        }
      }
    }
  };

  const toggelTaskBar = () => {
    if (ui.isSecondarySidebarVisible) {
      ui.setActiveScreen(MenuItemKeys.board);
      ui.setIsSecondarySidebarVisible(false);
      ui.setDataSource(null);
      navigate(`/user/${session.user?.uid}/board`);
    } else {
      ui.setActiveScreen(MenuItemKeys.tasks);
      handleOpenSourcedata("tasks");
      ui.setIsSecondarySidebarVisible(true);
    }
  };

  const handleOpenSourcedata = useCallback(
    (sourcedata) => {
      ui.setIsSidebarCollapsed(false);
      ui.setIsSecondarySidebarVisible(true);
      ui.setDataSource(sourcedata);
    },
    [ui]
  );

  return (
    // @ts-ignore
    <HStack w="100%" flexDirection={"row-reverse"} bg={"white"}>
      {ui.isSecondarySidebarVisible && (
        <SecondarySidebarContainer>
          {ui.dataSource && (
            <EventsSource source={ui.dataSource} key={ui.dataSource} />
          )}
        </SecondarySidebarContainer>
      )}
      <Stack h="100%" w="100%">
        <Stack h="10%" maxHeight={"85px"} bg="#fbfbfb">
          <Flex alignItems={"center"} h="100%" px="10px">
            <Box
              as="section"
              flexDirection={"row"}
              display={"flex"}
              gap={"10px"}
              alignItems={"center"}
              pl={"40px"}
            >
              <ControlsContainer>
                <TodayButton
                  onClick={() => {
                    if (calendarRef.current) {
                      const api = calendarRef.current.getApi();
                      api.today();
                      if (session.user?.preferences) {
                        const isWeek = session.user.preferences.calendarView;
                        if (isWeek === "timeGridDay") {
                          setCurrentWeek(dayjs(api.getDate()));
                        } else {
                          setCurrentWeek(dayjs().startOf("week"));
                        }
                      }
                    }
                  }}
                >
                  {t("calendar.today")}
                </TodayButton>
                <ActionButtonConitaner
                  onClick={() => {
                    if (calendarRef.current) {
                      const api = calendarRef.current.getApi();
                      api.prev();

                      if (session.user?.preferences) {
                        const isWeek = session.user.preferences.calendarView;
                        if (isWeek === "timeGridDay") {
                          setCurrentWeek(dayjs(api.getDate()));
                        } else {
                          setCurrentWeek(
                            dayjs(currentWeek)
                              .startOf("week")
                              .subtract(1, "week")
                          );
                        }
                      }
                    }
                  }}
                >
                  <Icon iconName="HiChevronLeft" />
                </ActionButtonConitaner>
                <ActionButtonConitaner
                  onClick={() => {
                    if (calendarRef.current) {
                      const api = calendarRef.current.getApi();
                      api.next();

                      if (session.user?.preferences) {
                        const isWeek = session.user.preferences.calendarView;
                        if (isWeek === "timeGridDay") {
                          setCurrentWeek(dayjs(api.getDate()));
                        } else {
                          setCurrentWeek(
                            dayjs(currentWeek).startOf("week").add(1, "week")
                          );
                        }
                      }
                    }
                  }}
                >
                  <Icon iconName="HiChevronRight" />
                </ActionButtonConitaner>
              </ControlsContainer>
              <MonthText>{showMonth()}</MonthText>
            </Box>
            <Spacer />
            <Box as="section" display={"flex"} gap={"10px"}>
              <section style={{ width: "100%", minWidth: "250px", zIndex: 10 }}>
                {organizationUsers?.length && (
                  <Select
                    options={organizationUsers as any}
                    value={activeUser as any}
                    isLoading={organization.isFetchingUsers}
                    onChange={(option) => {
                      if (option) {
                        board.fetchTimeBlockingEvents(option.value);
                        navigate(`/user/${option.value}/board`);
                      }
                    }}
                  />
                )}
              </section>
              <SettingsButton
                aria-label={"Settings"}
                onClick={() => setIsSettingsModalVisible(true)}
              >
                <Icon iconName={"HiOutlineCog"} />
              </SettingsButton>
              <SettingsButton
                aria-label={"taskShow"}
                onClick={() => toggelTaskBar()}
                isClick={ui.isSecondarySidebarVisible}
              >
                {ui.isSecondarySidebarVisible ? (
                  <Icon2 iconName={MdClose} size="small2" />
                ) : (
                  <Icon2 iconName={MdTaskAlt} size="small2" />
                )}
              </SettingsButton>
            </Box>
          </Flex>
        </Stack>
        <Stack h="90%" bg="#fbfbfb" w="100%">
          <FullCalendar
            key={ui.isSecondarySidebarVisible ? "resized" : "full-width"}
            plugins={[timeGridPlugin, interactionPlugin, googleCalendarPlugin]}
            dayHeaderContent={({
              text,
              date,
              isToday,
            }: DayHeaderContentArg) => {
              return renderHeadingDayCell(date, isToday);
              // return (
              //   <div style={{ backgroundColor: "red" }}>
              //     {text} - {getDayWorkLog(date)}
              //     {/* <button style={{position: 'absolute', top: '2px', right: '2px'}} onClick={() => handleAddInlineEvent(dayHeaderContent.date)} >+</button> */}
              //   </div>
              // );
            }}
            initialView={
              session.user?.preferences.calendarView || "timeGridWeek"
            } // timeGridWeek, timeGridDay
            headerToolbar={false}
            ref={calendarRef}
            eventSources={board.events}
            eventContent={renderEventContent}
            editable={true}
            selectable={true}
            selectMirror={true}
            droppable={true}
            // @ts-ignore
            eventReceive={handleReceiveEvent}
            select={handleDateSelect}
            scrollTime={moment().format("HH:mm:ss")}
            height={"100vh"} // TODO: Calc
            eventResize={handleResizeEvent}
            allDaySlot={session.user?.preferences.allDaySlot}
            weekends={session.user?.preferences.weekends}
            slotMinTime={session.user?.preferences.slotMinTime}
            slotMaxTime={session.user?.preferences.slotMaxTime}
            slotDuration={session.user?.preferences.slotDuration}
            businessHours={{
              // days of week. an array of zero-based day of week integers (0=Sunday)
              daysOfWeek: session.user?.preferences.businessDaysOfWeek,
              startTime: session.user?.preferences.businessStartTime, // a start time
              endTime: session.user?.preferences.businessEndTime, // an end time
            }}
            datesSet={handleDateRangeChange}
            nowIndicator
            eventDrop={handleResizeEvent}
            // dayMaxEvents={true}
            // allDayContent={() => "All-day"}
            // al

            // Use this function to edit all recurring events
            // eventAllow={(_, draggedEvent) => {
            //   if (draggedEvent?.extendedProps.recurrenceId) {
            //     setEditRecurrentEvent(draggedEvent);
            //   }

            //   return true;
            // }}
            //slotLabelFormat={"HH:mm"}
          />
        </Stack>
      </Stack>

      {/* {organizationUsers?.length > 0 && (
        <div
          style={{
            minWidth: "250px",
            position: "absolute",
            right: "1rem",
            top: "1rem",
          }}
        >
          <Select
            options={organizationUsers as any}
            value={activeUser as any}
            isLoading={organization.isFetchingUsers}
            onChange={(option) =>
              option && navigate(`/user/${option.value}/board`)
            }
          />
        </div>
      )} */}
      {/* EventModal */}
      {isModalEventVisible && (
        <Modal
          isOpen={isModalEventVisible}
          size={"xl"}
          onClose={handleCancelModal}
        >
          <ModalOverlay />
          <ModalContent maxW={"820px"}>
            <ModalCloseButton />
            <ModalBody style={{ padding: "1.5rem" }}>
              <EventForm
                data={eventData}
                projects={userProjects}
                isFetchingProjects={organization.isFetchingProjects}
                organizationId={session.user?.organization?.uid || ""}
                onSubmit={handleSubmitEvent}
                onCancel={handleCancelModal}
                onDelete={handleConfirmDeleteEvent}
                isSubmitting={board.isSubmitting}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* Settings Modal */}
      {isSettingsModalVisible && (
        <BoardSettingsModal
          preferences={session.user?.preferences}
          isVisible={isSettingsModalVisible}
          onClose={() => setIsSettingsModalVisible(false)}
          onPreferecensChange={handleUpdateUserPreferences}
        />
      )}

      {/* Edit recurring event */}
      <Modal isOpen={Boolean(editRecurrentEvent)} onClose={() => null}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody style={{ padding: "2rem" }}>
            <RadioGroup
              onChange={(value) =>
                setEditRecurrenceAction(RecurrenceEditAction[value])
              }
              value={editRecurrenceAction}
            >
              <Stack direction={"column"}>
                <Radio value={RecurrenceEditAction.onlyThis}>
                  {t<string>("screens.board.recurrence.onlyThis")}
                </Radio>
                <Radio value={RecurrenceEditAction.thisAndFollowingOnes}>
                  {t<string>("screens.board.recurrence.thisAndFollowingOnes")}
                </Radio>
              </Stack>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={"teal"}
              onClick={() => session.updateUser({ termsOfUse: true })}
              style={{ width: "100%" }}
            >
              {t<string>("common.confirm")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Delete Event Alert Dialog */}
      {isOpenDeleteEventDialog && (
        <DeleteEventDialog
          event={eventToDelete}
          isOpen={isOpenDeleteEventDialog}
          onDismiss={handleCancelDeleteEventDialog}
          onConfirm={handleDeleteEvent}
        />
      )}
    </HStack>
  );
});

// const HeadingDayCell = styled.div<{ isToday: boolean }>`
//   background-color: ${(props) => (props.isToday ? "#EDF1FB" : "transparent")};
//   border-radius: 0.75rem;
//   display: flex;
//   flex-direction: column;
//   min-width: 80%;
// `;
const HeadingDayCell = styled.div<{ isToday: boolean }>`
  background-color: ${(props) => (props.isToday ? "#319795" : "transparent")};
  height: 45px;
  width: 45px;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.isToday ? "white" : "black")};
  font-size: 24px;
  font-weight: 600;
`;

const DayCellTitle = styled.h2`
  color: #000;
  margin-bottom: 0;
`;

const DayCellSubtitle = styled.h3`
  color: #1a202c;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
`;

const ControlsContainer = styled.section`
  display: flex;
  gap: 10px;
`;
const TodayButton = styled.button`
  width: auto;
  height: 38px;
  padding: 5px 15px 5px 15px;
  border-radius: 8px;
  border-width: 2px;
  border-color: #dbdbdc;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.white};
`;

const ActionButtonConitaner = styled.button`
  width: 38px;
  height: 38px;
  border-radius: 8px;
  border-width: 2px;
  border-color: #dbdbdc;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.white};
`;

const MonthText = styled.span`
  font-size: 16px;
  font-weight: 400;
`;

type IconProps = {
  iconName: IconType;
  size?: string;
  color?: string;
  extraProps?: any;
};

const Icon2: React.FC<IconProps> = ({ iconName, size, color, extraProps }) => {
  let iconComponent = React.createElement(iconName);
  const iconSize =
    size === "xsmall"
      ? "0.65rem"
      : size === "small2"
      ? "20px"
      : size === "small"
      ? "1rem"
      : size === "large"
      ? "2.5rem"
      : size === "xlarge"
      ? "3rem"
      : size === "xxlarge"
      ? "3.8rem"
      : "1.4rem";

  return (
    <div style={{ fontSize: iconSize, color: color }} {...extraProps}>
      {iconComponent}
    </div>
  );
};
