import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { TFunction } from "i18next";
import { useCallback, useMemo } from "react";
import { useProjectsWorkflows } from "../../../hooks/useProjectsWorkflows";
import Project from "../../../models/Project";
import { ProjectForm } from "../../ProjectForm";
import { useNavigate } from "react-router-dom";
import { OrganizationStore } from "../../../stores/Organization";
import { ProjectFormBeta } from "../../ProjectFormBeta";
import { User } from "../../../types";

type ProjectModalProps = {
  isModalProjectVisible: boolean;
  organization: OrganizationStore;
  currentProject: Project;
  toast: any;
  organizationId: string;
  toggleIsProjectModalVisibility: () => void;
  t: TFunction;
  onCreateCustomer: () => void;
  userId: User["uid"] | undefined;
};

export const ProjectModal: React.FC<ProjectModalProps> = ({
  isModalProjectVisible,
  organization,
  currentProject,
  toast,
  organizationId,
  toggleIsProjectModalVisibility,
  t,
  onCreateCustomer,
  userId,
}) => {
  const navigate = useNavigate();

  const {
    isFetching: isFetchingProjectsWorkflows,
    data: projectsWorkflows = [],
  } = useProjectsWorkflows(organizationId);

  const users = useMemo(
    () => organization.users.filter((user) => user.status === "active"),
    [organization]
  );

  const handleUpdateProject = useCallback(
    async (project) => {
      try {
        await currentProject?.update(project);

        toast({
          title: t("screens.projects.actions.updateSuccess"),
          status: "success",
          position: "bottom-left",
        });

        toggleIsProjectModalVisibility();
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
          position: "bottom-left",
        });
      }
    },
    [currentProject, toast, t, toggleIsProjectModalVisibility]
  );

  const handleDeleteProject = useCallback(
    async (projectId: Project["uid"]) => {
      if (window.confirm(t("screens.projects.actions.delete"))) {
        try {
          await organization.deleteProject(organizationId, projectId);
          if (userId) {
            navigate(
              `/user/${userId}/projects?organizationId=${organizationId}`
            );
          }
        } catch (err) {
          toast({
            title: t("common.error"),
            status: "error",
            position: "bottom-left",
          });
        }
      }
    },
    [t, organization, organizationId, navigate, toast]
  );

  return (
    <Modal
      isOpen={isModalProjectVisible}
      onClose={toggleIsProjectModalVisibility}
    >
      <ModalOverlay />
      {/* @ts-ignore */}
      <ModalContent
        maxW={"820px"}
        maxH={"80%"}
        overflow={"hidden"}
        overflowY={"scroll"}
      >
        <ModalHeader>{t<string>("screens.projects.editProject")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ProjectFormBeta
            customers={organization.customers}
            categories={organization.projectCategories}
            users={users}
            projectsWorkflows={projectsWorkflows}
            onCreateCustomer={onCreateCustomer}
            onCancel={toggleIsProjectModalVisibility}
            project={currentProject}
            onSubmit={handleUpdateProject}
            isFetchingCategories={organization.isFetchingProjectCategories}
            isFetchingCustomers={organization.isFetchingCustomers}
            isFetchingProjectsWorkflows={isFetchingProjectsWorkflows}
            onLoad={() => {
              organization.fetchProjectCategories(organizationId);
              organization.fetchUsers(organizationId);
              organization.fetchCustomers(organizationId);
              organization.fetchProjectArea(organizationId);
            }}
            onDelete={handleDeleteProject}
            areas={organization.projectAreas}
          />
          {/* <ProjectForm
            project={currentProject}
            onCancel={toggleIsProjectModalVisibility}
            onSubmit={handleUpdateProject}
            users={users}
            isFetchingUsers={organization.isFetchingUsers}
            categories={organization.projectCategories}
            isFetchingCategories={organization.isFetchingProjectCategories}
            onDelete={handleDeleteProject}
            onLoad={() => {
              organization.fetchProjectCategories(organizationId);
              organization.fetchUsers(organizationId);
              organization.fetchCustomers(organizationId);
            }}
            customers={organization.customers}
            isFetchingCustomers={organization.isFetchingCustomers}
            isFetchingProjectsWorkflows={isFetchingProjectsWorkflows}
            projectsWorkflows={projectsWorkflows}
            onCreateCustomer={onCreateCustomer}
          /> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
