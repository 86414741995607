import styled from "styled-components";
import { NavItemText } from "../../components/Nav";

export const BrandContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease;
  margin-top: 1rem;

  &:hover {
    opacity: 0.75;
  }

  // h5 {
  //   margin-left: $ {(props) => props.theme.global.padding.small};
  //   font-size: $ {(props) => props.theme.global.typography.h5.fontSize};
  //   font-weight: $ {(props) => props.theme.global.typography.text.fontWeight};
  // }

  // @media (min-width: $ { (props) =>
  //  props.theme.global.breakpoints.large.value}) {
  //   padding-top: 0;
  // }
`;

export const BrandIcon = styled.div`
  display: block;
  width: 52px;
  height: 52px;

  img {
    width: 100%;
  }
`;

export const SidebarContainer = styled.aside<{
  isCollapsed: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  min-width: 0;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  background: ${(props) => props.theme.bg1};
  // border-right: 1px solid $ {(props) => props.theme.global.colors.border.normal};
  // border-top: 1px solid $ {(props) => props.theme.global.colors.border.normal};

  // & > *:first-child {
  //   margin-top: $ {(props) => props.theme.global.padding.medium};
  // }

  // & > *:last-child {
  //   margin-bottom: $ {(props) => props.theme.global.padding.medium};
  // }

  // & > $ {NavWrapper}:last-child {
  //   border-top: 0;
  // }

  ${(props) =>
    props.isCollapsed
      ? `
      max-width: 70px;
      h5, ${NavItemText} {
        display: none !important;
      }
      ${BrandContainer} {
        padding-left: 1rem;
      }
      ${BrandIcon} img {
        max-width: 70%;
      }
      `
      : `
      width: 200px;
      `}
`;

export const SecondarySidebarContainer = styled.div`
  height: 93vh;
  margin-bottom: auto;
  width: 360px;
  background: ${(props) => props.theme.bg1};
`;

export const SubMenuItemContainer = styled.section`
  display: flex;
  width: 100%;
  padding: 8px 16px 8px 16px;
  border-radius: 0.5em;
  // background: #1b48bb1a;
  :hover {
    cursor: pointer;
    background-color: #0000000a;
  }
`;
