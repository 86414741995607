import { HStack } from "@chakra-ui/layout";
import React, { ReactNode } from "react";
import styled from "styled-components";
import Project from "../../models/Project";
import { ServiceCode, TaskCategory, TaskPriority, User } from "../../types";
import { Avatar } from "../Avatar";
import { Card } from "../Card";
import { PriorityIcon } from "../PriorityIcon";
import { Checkbox } from "@chakra-ui/checkbox";
import {
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  PopoverHeader,
} from "@chakra-ui/popover";
import { TFunction } from "i18next";
import { Icon } from "../Icon";
import { Integrations } from "../../models/Integrations";

type Props = {
  title: string;
  sourceId: string;
  source: ServiceCode;
  className: string;
  description?: string;
  project?: Project | null;
  icon?: any;
  color?: string;
  complete?: boolean;
  due?: string;
  permalinkUrl?: string;
  disabled?: boolean;
  onClick?: (esternalId: string) => void;
  onCompleted?: (esternalId: string) => void;
  t?: TFunction;
  showId?: boolean;
  assignees?: User[];
  priority?: TaskPriority;
  category?: TaskCategory | null;
  children?: ReactNode;
  parentDescription?: string;
  projectTitle?: string;
};

const getTodoistColorSchemeByPriority = (priority: TaskPriority) => {
  switch (priority) {
    case 10:
      return "red";
    case 20:
      return "orange";
    case 30:
      return "blue";
    default:
      return undefined;
  }
};

export const ExternalEvent: React.FC<Props> = ({
  title,
  sourceId,
  source,
  className,
  description,
  project,
  icon,
  color,
  due,
  permalinkUrl,
  disabled,
  onClick,
  onCompleted,
  t,
  showId = true,
  complete = false,
  assignees,
  priority,
  category,
  parentDescription,
  children,
  projectTitle,
}) => {
  // const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

  return (
    <Card
      className={className}
      color={category?.color || undefined}
      data-title={title}
      data-source-id={sourceId}
      data-source={source}
      data-color={color || ""}
      data-project-id={project?.uid}
      data-category-id={category?.uid}
      style={{
        maxWidth: "250px",
        cursor: "pointer",
        wordBreak: "break-all",
        margin: "0.2rem",
        pointerEvents: disabled ? "none" : "initial",
      }}
      onClick={() => (onClick && !disabled ? onClick(sourceId) : {})}
    >
      {disabled && <DisabledOverlay />}

      <TitleContainer>
        {onCompleted && t && (
          <span style={{ display: "block" }}>
            <Popover trigger="hover" arrowPadding={17} gutter={20}>
              <PopoverTrigger>
                <Checkbox
                  name={title}
                  isChecked={complete}
                  onChange={(event) => {
                    onCompleted(sourceId);
                  }}
                  size={"lg"}
                  {...(priority &&
                    source === Integrations.TODOIST && {
                      colorScheme: getTodoistColorSchemeByPriority(priority),
                    })}
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>
                  {t<string>("screens.integrations.tasks.markCompleted")}
                </PopoverHeader>
              </PopoverContent>
            </Popover>
          </span>
        )}
        <div
          style={{
            textDecoration: complete ? "line-through" : "none",
            wordBreak: "keep-all",
          }}
        >
          {title}
        </div>
      </TitleContainer>
      {projectTitle && (
        <HStack paddingLeft={"25px"}>
          <div style={{ fontSize: "0.75rem", fontWeight: "bold" }}>
            {projectTitle}
          </div>
        </HStack>
      )}
      <HStack paddingLeft={"25px"}>
        {parentDescription && (
          <div style={{ fontSize: "0.75rem", fontWeight: "bold" }}>
            {parentDescription}
          </div>
        )}
      </HStack>
      <HStack>
        <HStack
          minWidth={"20px"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {priority && <PriorityIcon priority={priority} size={"small"} />}
          {project && <ExternalId>{project.title}</ExternalId>}
        </HStack>

        {due ? (
          <DueDateText overDue={new Date().getTime() - new Date(due).getTime()}>
            {/* <Icon iconName={"HiOutlineCalendar"} /> */}
            {new Date(due).toLocaleDateString()}
          </DueDateText>
        ) : (
          <span></span>
        )}

        <HStack>
          {assignees &&
            assignees.map((assignee) => (
              <Avatar
                key={assignee.uid}
                values={[assignee.nominative]}
                src={assignee.imageUrl}
                size={"xs"}
              />
            ))}
        </HStack>
      </HStack>
      <HStack>
        {showId && (
          <div>
            {icon}
            <ExternalId>{sourceId}</ExternalId>
          </div>
        )}
        {/* {description && isDescriptionVisible && (
          <Description
            dangerouslySetInnerHTML={{ __html: description }}
          ></Description>
        )} */}
      </HStack>
      {/* {t && description && description !== "<body></body>" && (
        <HStack justifyContent={"center"} margin={"5px 0"}>
          <ShowDetails
            onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}
          >
            {t<string>(
              `screens.integrations.tasks.${
                isDescriptionVisible ? "hideDetails" : "showDetails"
              }`
            )}
          </ShowDetails>
        </HStack>
      )} */}
      <HStack justifyContent={"space-between"} mt={"5px"}>
        {permalinkUrl && t && (
          <ExternalLink href={permalinkUrl} target={"_blank"}>
            {t<string>(`screens.integrations.tasks.viewOn${source}`)}
            <Icon iconName={"HiOutlineArrowNarrowRight"} />
          </ExternalLink>
        )}
      </HStack>
    </Card>
  );
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
`;

// const Description = styled.div`
//   margin-top: 0.8rem;
//   margin-bottom: 0.5rem;
//   word-break: break-all;
//   opacity: 0.75;
// `;

const DueDateText = styled.span<{ overDue: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  font-weight: 600;
  color: ${(props) =>
    props.overDue > 86400000
      ? "#E53E3E"
      : props.overDue < 86400000 && props.overDue > 0
      ? "#D69E2E"
      : "#38A169"};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
`;

const ExternalId = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  font-weight: 600;
  color: rgb(122, 134, 154);
`;

const ExternalLink = styled.a`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  font-weight: 600;
  color: rgb(122, 134, 154);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: rgb(0, 0, 0);
  }
`;

// const ShowDetails = styled.div`
//   overflow: hidden;
//   text-overflow: ellipsis;
//   font-size: 0.75rem;
//   font-weight: 600;
//   color: rgb(122, 134, 154);
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   background-color: rgba(122, 134, 154, 0.1);
//   padding: 5px 15px;
//   border-radius: 5px;
// `;

const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 10;
`;
